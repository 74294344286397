/* eslint-disable */
import { Button } from '@mui/material';

export default function ProxyServer() {
    return (
        <Button 
            sx={{ minWidth: 160 }}
            variant='contained' 
            color='success' 
            onClick={() => {window.location.href="https://cors.proxy.jamesakweter.tech/CORSProxy.zip", "_blank"}}
        >
            CORS Proxy
        </Button>
    );
}
